<template>
  <div class="friendly-link-list">
    <el-card>
      <div class="toolbar">
        <el-button type="primary" @click="handleAdd">添加友情链接</el-button>
      </div>

      <!-- PC端表格 -->
      <el-table 
        v-if="!isMobile"
        :data="tableData" 
        v-loading="loading"
      >
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="图标" width="80">
          <template slot-scope="scope">
            <el-image 
              style="width: 40px; height: 40px"
              :src="scope.row.icon === '/static/default-link.png' ? '/static/default-link.png' : scope.row.icon"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="120">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.sort"
              :min="0"
              size="small"
              style="width: 100px"
              controls-position="right"
              @change="(value) => handleSortChange({...scope.row, sort: value})"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" class="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <el-card 
          v-for="item in tableData" 
          :key="item.id" 
          class="mobile-item"
          shadow="hover"
        >
          <div class="mobile-item-header">
            <div class="mobile-item-icon">
              <el-image 
                style="width: 40px; height: 40px"
                :src="item.icon === '/static/default-link.png' ? '/static/default-link.png' : item.icon"
                fit="cover"
              ></el-image>
            </div>
            <div class="mobile-item-info">
              <div class="mobile-item-title">{{ item.title }}</div>
              <div class="mobile-item-desc">{{ item.description }}</div>
            </div>
          </div>
          <div class="mobile-item-body">
            <div class="mobile-item-row">
              <span class="label">排序：</span>
              <el-input-number
                v-model="item.sort"
                :min="0"
                style="width: 120px"
                size="small"
                controls-position="right"
                @change="(value) => handleSortChange({...item, sort: value})"
              ></el-input-number>
            </div>
            <div class="mobile-item-row">
              <span class="label">状态：</span>
              <el-switch
                v-model="item.status"
                @change="handleStatusChange(item)"
              ></el-switch>
            </div>
          </div>
          <div class="mobile-item-footer">
            <el-button 
              type="primary" 
              size="mini" 
              @click="handleEdit(item)"
            >编辑</el-button>
            <el-button 
              type="danger" 
              size="mini" 
              @click="handleDelete(item)"
            >删除</el-button>
          </div>
        </el-card>
      </div>
    </el-card>

    <!-- 编辑对话框 -->
    <el-dialog 
      :title="dialogTitle" 
      :visible.sync="dialogVisible"
      :width="isMobile ? '95%' : '500px'"
      :fullscreen="isMobile"
      custom-class="friendly-link-dialog"
    >
      <el-form 
        :model="form" 
        :rules="rules" 
        ref="form" 
        :label-width="isMobile ? 'auto' : '80px'"
        :label-position="isMobile ? 'top' : 'right'"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="form.url" placeholder="请输入完整的URL，包含http://或https://"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-radio-group v-model="iconType" @change="handleIconTypeChange">
            <el-radio label="custom">自定义图标</el-radio>
            <el-radio label="default">默认图标</el-radio>
          </el-radio-group>
          <div v-if="iconType === 'custom'" style="margin-top: 10px">
            <el-input v-model="form.icon" placeholder="请输入图标URL地址"></el-input>
            <div class="icon-preview" v-if="form.icon">
              <el-image 
                style="width: 40px; height: 40px; margin-top: 10px"
                :src="form.icon"
                fit="cover"
              >
                <div slot="error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <div v-else class="default-icon-preview">
            <el-image 
              style="width: 40px; height: 40px"
              src="/static/default-link.png"
              fit="cover"
            ></el-image>
            <span class="tip">使用默认图标</span>
          </div>
        </el-form-item>
        <el-form-item label="背景色">
          <el-color-picker v-model="form.bgColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="form.sort" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch v-model="form.status"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button 
          @click="dialogVisible = false"
          :size="isMobile ? 'medium' : 'small'"
        >取 消</el-button>
        <el-button 
          type="primary" 
          @click="handleSubmit" 
          :loading="submitting"
          :size="isMobile ? 'medium' : 'small'"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    // URL验证规则
    const validateUrl = (rule, value, callback) => {
      if (value === '/static/default-link.png') {
        callback();
      } else if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
        callback(new Error('请输入以http://或https://开头的完整URL'));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      tableData: [],
      dialogVisible: false,
      dialogTitle: '添加友情链接',
      submitting: false,
      form: {
        id: null,
        title: '',
        description: '',
        url: '',
        icon: '',
        bgColor: '#f8f9fa',
        sort: 0,
        status: true
      },
      rules: {
        title: [
          { required: true, message: '请入标题', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入链接地址', trigger: 'blur' },
          { validator: validateUrl, trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请选择或输入图标地址', trigger: 'change' },
          { validator: validateUrl, trigger: 'blur' }
        ]
      },
      isMobile: false,  // 添加移动端判断
      iconType: 'custom', // 添加图标类型选择
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        const res = await this.$http.get('/api/admin/friendly-links')
        if (res.data.code === 200) {
          this.tableData = res.data.data
        }
      } catch (error) {
        console.error('获取友情链接列表失败:', error)
        this.$message.error('获取列表失败')
      } finally {
        this.loading = false
      }
    },
    handleAdd() {
      this.dialogTitle = '添友情链接'
      this.form = {
        id: null,
        title: '',
        description: '',
        url: '',
        icon: '',
        bgColor: '#f8f9fa',
        sort: 0,
        status: true
      }
      this.dialogVisible = true
    },
    handleEdit(row) {
      this.dialogTitle = '编辑友情链接'
      this.form = { ...row }
      this.iconType = row.icon === '/static/default-link.png' ? 'default' : 'custom'
      this.dialogVisible = true
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        this.submitting = true
        
        const api = this.form.id 
          ? `/api/admin/friendly-links/${this.form.id}`
          : '/api/admin/friendly-links'
        const method = this.form.id ? 'put' : 'post'
        
        const res = await this.$http[method](api, this.form)
        if (res.data.code === 200) {
          this.$message.success('保存成功')
          this.dialogVisible = false
          this.fetchData()
        }
      } catch (error) {
        console.error('保存失败:', error)
        this.$message.error('保存失败')
      } finally {
        this.submitting = false
      }
    },
    async handleDelete(row) {
      try {
        await this.$confirm('确认删除该友情链接?', '提示', this.getConfirmConfig('确认删除该友情链接?'))
        const res = await this.$http.delete(`/api/admin/friendly-links/${row.id}`)
        if (res.data.code === 200) {
          this.$message.success('删除成功')
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          this.$message.error('删除失败')
        }
      }
    },
    async handleStatusChange(row) {
      try {
        await this.$http.put(`/api/admin/friendly-links/${row.id}`, {
          status: row.status
        })
      } catch (error) {
        console.error('更新状态失败:', error)
        row.status = !row.status
        this.$message.error('更新状态失败')
      }
    },
    async handleSortChange(row) {
      try {
        await this.$http.put(`/api/admin/friendly-links/${row.id}`, {
          sort: row.sort
        })
      } catch (error) {
        console.error('更新排序失败:', error)
        this.fetchData()
        this.$message.error('更新排序失败')
      }
    },
    // 检查设备类型
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    // 处理图标类型切换
    handleIconTypeChange(type) {
      if (type === 'default') {
        this.form.icon = '/static/default-link.png';
      } else {
        this.form.icon = '';
      }
    },
    getConfirmConfig(message, title = '提示') {
      return {
        title,
        message,
        customClass: 'mobile-confirm-dialog',
        center: this.isMobile,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: this.isMobile ? 'confirm-button' : '',
        cancelButtonClass: this.isMobile ? 'cancel-button' : '',
        type: 'warning'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.friendly-link-list {
  padding: 20px;
  
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  
  .toolbar {
    margin-bottom: 20px;
  }

  .mobile-list {
    .mobile-item {
      margin-bottom: 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .mobile-item-header {
        display: flex;
        margin-bottom: 15px;
        
        .mobile-item-icon {
          margin-right: 15px;
        }
        
        .mobile-item-info {
          flex: 1;
          
          .mobile-item-title {
            font-size: 16px;
            font-weight: 500;
            color: #303133;
            margin-bottom: 8px;
          }
          
          .mobile-item-desc {
            font-size: 14px;
            color: #909399;
          }
        }
      }
      
      .mobile-item-body {
        margin-bottom: 15px;
        
        .mobile-item-row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          .label {
            width: 60px;
            font-size: 14px;
            color: #606266;
          }
        }
      }
      
      .mobile-item-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }

  .danger {
    color: #F56C6C;
  }
}

// 移动端弹窗样式
.friendly-link-dialog {
  @media screen and (max-width: 768px) {
    .el-dialog__body {
      padding: 15px !important;
    }
    
    .el-form-item {
      margin-bottom: 15px;
    }
    
    .el-form-item__label {
      padding-bottom: 8px;
      line-height: 1.2;
    }
    
    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      
      .el-button {
        flex: 1;
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    .mobile-item-header {
      .mobile-item-info {
        .mobile-item-title {
          color: #fff !important;
        }
        
        .mobile-item-desc {
          color: #909399 !important;
        }
      }
    }
    
    .mobile-item-body {
      .mobile-item-row {
        .label {
          color: #909399 !important;
        }
      }
    }
  }
}

.default-icon-preview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  
  .tip {
    color: #909399;
    font-size: 14px;
  }
}
</style> 